import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizedUserGuard  {
    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.authService) {
            return this.router.createUrlTree(['/auth/login']);
        } else {
            return true;
        }
    }
}
