<!-- <div class = apps-outer>
    <div class="app-grid">
        <div *ngFor="let appRow of appRows" class="app-row">
            <div *ngFor="let app of appRow" class="app-card">
                <a [href]="app.APP_URL" target="_blank" class="card-link">
                    <img [src]="app.LOGO_URL" alt="{{ app.NAME }} Logo" class="card-img" />
                    <div class="card-content">
                        {{ app.NAME }}
                    </div>
                </a>
            </div>
        </div>
    </div>
</div> -->

<div class="apps-grid">
    <div 
        class="grid-item app-card"
        *ngFor="let app of apps"
    >
        <a 
            [href]="app.APP_URL" 
            target="_blank"
            class="app-link"
        >
            <img 
                [src]="app.LOGO_URL" 
                alt="{{ app.NAME }} Logo"
                class="app-image"
            />
            <div class="app-name">{{ app.NAME }}</div>
        </a>
    </div>
</div>