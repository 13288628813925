import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';

import { SessionService } from './session.service';
import { environment } from '../../../environments/environment';
import { LoginRequest, LoginResponse } from '../models/authentication/auth.model';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

export const InterceptorSkip = 'X-Skip-Interceptor';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    fetchUsersAction = new BehaviorSubject(false);
    onFormReset = new Subject<void>();
    InterceptorSkipHeader = new HttpHeaders({
      'X-Skip-Interceptor': ''
    });
    loggedIn = false;
    isPasswordCorrect$ = new Subject<boolean>();

    constructor(private http: HttpClient,
      private router: Router,
      private sessionService: SessionService,
      @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
      private msalService: MsalService,
      ) {}

    resetForm(): void {
        this.onFormReset.next();
    }

    login(loginData: LoginRequest): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(environment.BASE_API + environment.LOGIN_API,
          loginData,
          { headers: this.InterceptorSkipHeader })
          .pipe(
            map((response: LoginResponse) => {
              this.sessionService.setSessionObject(response);
              return response;
            }),
            catchError((error) => this.handleError(error))
          );
      }

    private handleError(error: any): Observable<never> {
      const errMsgStatus = error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      const errMsg = error.message ? error.message : errMsgStatus;
      this.isPasswordCorrect$.next(false);
      return throwError(() => new Error(errMsg));
    }

    private clearCookies() {
      const cookies = document.cookie.split(';');
  
      cookies.forEach(cookie => {
          const trimmedCookie = cookie.trim();
  
          if (trimmedCookie.startsWith('WGINB')) {
              const cookieName = trimmedCookie.split('=')[0];  
              document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          }
      });
  }
  
  logout(): void {
      this.sessionService.clearSession();
      this.clearCookies();
      sessionStorage.clear();
      this.router.navigate(['/login']);
  }

  loginSso() {
    if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
        this.msalService.loginRedirect();
    }
  }

  loginUsingSso(loginData: any) {
    return this.http.post<LoginResponse>(environment.BASE_API + environment.SSO_LOGIN_API,
      loginData,
      { headers: this.InterceptorSkipHeader })
      .pipe(
        map((response: LoginResponse) => {
          this.sessionService.setSessionObject(response);
          return response;
        }),
        catchError((error) => this.handleError(error))
      );
  }
}
