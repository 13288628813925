import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { ErrorInterceptor } from './shared/interceptor/error.interceptor';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { NavbarComponent } from './navbar/navbar.component';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { environment } from '../environments/environment';

const msalConfig = environment.MSAL_CONFIG;
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ProgressSpinnerModule,
    FormsModule,
    AuthModule,
    DashboardModule,
    CardModule,
    ButtonModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: msalConfig.clientId,
          authority: msalConfig.authority,
          redirectUri: msalConfig.redirectUri,
          postLogoutRedirectUri: msalConfig.postLogoutRedirectUri
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: isIE
        }
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read"]
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(
          [
            ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
          ]
        )
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
