import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  constructor( private http: HttpClient) {}

  fetchAppList() {
    const url = environment.BASE_API + environment.APP_LIST_API;
    return this.http.get<Response>(url).pipe(
      map((response: Response) => JSON.parse(JSON.stringify(response.body))));
  }
}
