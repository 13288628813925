import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { jwtDecode } from "jwt-decode";
import { BehaviorSubject } from 'rxjs';

import { LoginRequest, LoginResponse } from '../models/authentication/auth.model';

@Injectable({ providedIn: 'root' })

export class SessionService {
    authStatus = new BehaviorSubject(false);

    constructor(private router: Router) { }
    updateAuthStatus(status: boolean, loginData: LoginRequest): void {
        sessionStorage.setItem('username', JSON.stringify(loginData.username));
        this.authStatus.next(status);
    }
    setSessionObject(sessionData: LoginResponse): void {
        sessionStorage.setItem('wginb-session', JSON.stringify(sessionData));
        this.storeWginbKeysInCookies(sessionData);
        this.authStatus.next(true);
    }

    getSessionData(value: keyof LoginResponse): string | number {
        const session: LoginResponse = JSON.parse(sessionStorage.getItem('wginb-session')!);
        return session[value];
    }

    getTokenData(value: keyof LoginResponse | string): string {
        const session: LoginResponse = JSON.parse(sessionStorage.getItem('wginb-session')!);
        const tokenData: any = jwtDecode(session.idtoken);
        return tokenData[value];
    }

    isLoggedIn(): boolean {
        const session: LoginResponse = JSON.parse(sessionStorage.getItem('wginb-session')!);
        if (session === null) {
            return false;
        } else {
            const value: string = 'exp';
            const timeExpired = new Date().getTime() > (parseInt(this.getTokenData(value), 10)) * 1000;
            return !timeExpired;
        }
    }

    storeWginbKeysInCookies(data: any): void {
        const wginbKeys: string[] = Object.keys(data).filter((key) => key.startsWith('WGINB'));

        wginbKeys.forEach((key) => {
            const value = data[key];

            this.setCookie(key, JSON.stringify(value));
        });
    }

    private setCookie(name: string, value: string): void {
    document.cookie = `${name}=${value}; path=/;domain=.wginb.com`;
    }

    clearSession(): void {
        sessionStorage.removeItem('wginb-session');
        sessionStorage.removeItem('username');
        this.authStatus.next(false);
    }
}
