import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../../shared/services/app-data.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit{
  constructor(private spinnerService: SpinnerService, private appDataService: AppDataService) {}
  appList:any = [];

  ngOnInit(): void {
    this.getAppData();
    this.spinnerService.showSpinner();
  }

  getAppData(){
    this.spinnerService.showSpinner();
    this.appDataService.fetchAppList().subscribe((data) => {
      this.appList = data
    })
  }
}
