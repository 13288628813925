import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionService } from './shared/services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sso-apps-portal-ui';
  authStatus = false;
  authSubcription$: Subscription = new Subscription();
  constructor(private sessionService: SessionService, private router: Router) { }

    ngOnInit(): void {
      this.authSubcription$ = this.sessionService.authStatus.subscribe(() => {
        this.setAuthStatus();
      });
    }

    setAuthStatus(): void {
      this.authStatus = this.sessionService.isLoggedIn();
    }
    getAuthStatus() {
      return this.sessionService.isLoggedIn();
    }
    ngOnDestroy(): void {
      this.authSubcription$?.unsubscribe();
    }
}
