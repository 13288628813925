export const environment = {
  production: false,
  BASE_API: 'https://dev.api.apps.wginb.com',
  LOGIN_API: '/wgappsportallogin/login',
  SSO_LOGIN_API: '/wgappsportallogin/ssologin',
  APP_LIST_API: '/wgappsportal/apps/list',
  RESET_PASSWORD: 'https://dev.portal.reset.wginb.com',
  MSAL_CONFIG: {
    clientId:"f529d809-fdd8-4726-9659-2fc6c6f8f51d",
    authority: "https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808",
    redirectUri: "https://dev.portal.apps.wginb.com",
    postLogoutRedirectUri: "https://dev.portal.apps.wginb.com"
  }
};
