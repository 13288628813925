<div class="sticky-top nav bg-white container-fluid d-flex flex-column px-5 ">
    <div class="d-flex justify-content-between align-items-center">
      <div class="brand">
        <img src="assets/images/watchguard-logo.svg"
          class="img-fluid wg-logo"
        />
      </div>
      <h3 class="mt-1">WGINB Apps Portal</h3>
      <div class="d-flex align-items-center gap-3">
          <p class="text-center pt-3">{{ username }}</p>
        <button (click)="logout()" class="btn btn-secondary logout-btn">Logout</button>
      </div>
    </div>
</div>
  