<div class="w-100 d-flex flex-column gap-4 align-items-center mt-5">
    <img class="logo img-fluid" src="assets/images/watchguard-logo.svg">
    <h1>WG APPS</h1>
    <div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
        <div class="d-flex flex-column gap-4 align-items-center justify-content-center mt-3">
          <div class="d-flex gap-2 align-items-center">
            <label for="uname"><b>Username</b></label>
            <input class="form-control" type="text" placeholder="Enter Username" name="uname" required
              [(ngModel)]="loginData.username">
          </div>
          <div class="d-flex gap-2 align-items-center">
            <label for="psw"><b>Password</b></label>
            <input class="form-control" type="password" placeholder="Enter Password" name="psw" required
              [(ngModel)]="loginData.password">
          </div>
          <div class=error-message *ngIf="!isPasswordCorrect">
            invalid username or password
          </div>
          <button class="btn login-btn text-white" type="submit">Login</button>
  
          <a
            class="reset-password"  
            target="_blank" 
            appendTo="body" 
            pTooltip="MFA should be enabled for resetting your password" 
            tooltipPosition="right" 
            href="{{ resetPasswordUrl }}">
            Forgot Password? 
          </a>
        </div>
      </form>
      <div class="d-flex flex-column align-items-center justify-content-center mt-3">
        <p>OR</p>
        <button class="btn login-sso-btn" (click)="ssoLogin()">Login SSO</button>
      </div>
    </div>
  </div>